import { useState, useEffect, useCallback, useRef } from 'react';
import { axiosInstance as axios } from '../services/axiosInstance';
import { BetSuggestion, Race, BalanceInterface, RaceSchedule, Card } from '../interfaces';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { refreshDataEventEmitter } from '../events';

const useBets = () => {
  const [race, setRace] = useState<Race | undefined>(undefined); //this is the next race
  const [balance, setBalance] = useState<BalanceInterface | undefined>(undefined);
  const [bets, setBets] = useState<BetSuggestion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [placedBets, setPlacedBets] = useState<any[]>([]);
  const [username, setUsername] = useState<string>('');
  const [raceSchedule, setRaceSchedule] = useState<RaceSchedule[]>([]);
  const [selectedRace, setSelectedRace] = useState<any | undefined>(undefined);
  const [todayCards, setTodayCards] = useState<Card[]>([]);

  const hasFetchedData = useRef(false);

  const fetchBets = useCallback(async () => {
    try {
      let suggestedBetsUrl = 'api/v2/get-suggested-bets';

      if (localStorage.getItem('selectedCard') && localStorage.getItem('selectedCard') !== 'ALL') {
        suggestedBetsUrl += '?track=' + localStorage.getItem('selectedCard');
      }

      const response = await axios.get(suggestedBetsUrl);
      setBets(response.data.data.bets);
      setRace(response.data.data.race);
      setUsername(response.data.username);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching bets:', error);
    }
  }, []);

  const getBalance = useCallback(async () => {
    try {
      const response = await axios.get('api/get-balance');
      setBalance(response.data);
    } catch (error: any) {
      console.error('Error fetching balance:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('sessionToken');
        setLoggedIn(false);
        window.location.replace('/');
      }
    }
  }, []);

  const getRaceSchedule = useCallback(async () => {
    try {
      const response = await axios.get('api/v2/daily-schedule');
      setRaceSchedule(response.data.data);
    } catch (error) {
      console.error('Error fetching daily schedule:', error);
    }
  }, []);

  const getMyBets = useCallback(async () => {
    try {
      const response = await axios.get('api/placed-bets');
      setPlacedBets(response.data.data);
    } catch (error) {
      console.error('Error fetching placed bets:', error);
    }
  }, []);

  const getTodayCards = useCallback(async () => {
    try {
      const response = await axios.get('api/today-tracks');
      const cards = response.data.data;
      setTodayCards([...cards]);
    } catch (error) {
      console.error("Error fetching today's cards:", error);
    }
  }, []);

  const placeBet = async (betIDs: Array<number>, groupId?: string) => {
    setLoading(true);
    try {
      await axios.post(
        'api/v2/place-bet',
        JSON.stringify({
          suggested_bet_id: betIDs,
        })
      );

      if (groupId) {
        const newSuggestedBets = bets.filter((bet: BetSuggestion) => bet.groupId !== groupId);
        setBets([...newSuggestedBets]);
      }

      toast.success("You're down!");
      refreshDataEventEmitter.emit('refreshData');
    } catch (error: any) {
      console.error('Error placing bet:', error);
      if (error.status === 514) {
        //token most likely expired
        localStorage.removeItem('sessionToken');
        window.location.replace('/');
      }
    } finally {
      setLoading(false);
    }
  };

  const getSingleRace = useCallback(async (raceId: number) => {
    setLoading(true);
    try {
      const response = await axios.get(`api/v2/race/${raceId}`);
      setSelectedRace(response.data?.data);
    } catch (error) {
      toast.error('Error getting race details');
    } finally {
      setLoading(false);
    }
  }, []);

  const skipRace = async (raceId: number) => {
    setLoading(true);
    try {
      await axios.patch(`api/v2/race/${raceId}/skip`);
    } catch (error) {
      toast.error('Error skipping race:' + raceId);
    } finally {
      setLoading(false);
      getRaceSchedule();
    }
  };

  const followRace = async (raceId: number) => {
    setLoading(true);
    try {
      await axios.patch(`api/v2/race/${raceId}/follow`);
    } catch (error) {
      toast.error('Error following race:' + raceId);
    } finally {
      setLoading(false);
      getRaceSchedule();
    }
  };

  useEffect(() => {
    if (!hasFetchedData.current) {
      const fetchAllData = async () => {
        setLoading(true);
        await Promise.all([getBalance(), fetchBets(), getMyBets(), getRaceSchedule(), getTodayCards()]);
        setLoading(false);
      };

      fetchAllData();
      hasFetchedData.current = true;
    }
  }, [fetchBets, getBalance, getMyBets, getRaceSchedule, getTodayCards]);

  return {
    race,
    bets,
    loading,
    fetchBets,
    placeBet,
    balance,
    getBalance,
    getMyBets,
    placedBets,
    username,
    raceSchedule,
    setRaceSchedule,
    skipRace,
    followRace,
    getRaceSchedule,
    selectedRace,
    getSingleRace,
    loggedIn,
    setLoggedIn,
    todayCards,
    setBets,
    setRace,
    getTodayCards,
  };
};

export default useBets;
