import React, { useEffect, useState } from 'react';
import { Race } from '../interfaces';
import { LuMenu } from 'react-icons/lu';
import useBets from '../hooks/useBets';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { refreshDataEventEmitter } from '../events';
import { RxVideo } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';

interface RaceInfoProps {
  race?: Race;
}

const RaceInfo: React.FC<RaceInfoProps> = ({ race }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { todayCards, getTodayCards } = useBets();
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('selectedCard')) {
      setSelectedCard(localStorage.getItem('selectedCard'));
    }

    const intervalId = setInterval(() => {
      getTodayCards();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [getTodayCards, todayCards]);

  const handleLiveButtonClick = async () => {
    navigate('/video');
  };

  const selectCardHandler = function (waigrCode: string) {
    localStorage.setItem('selectedCard', waigrCode);
    setIsNavOpen(false);
    setSelectedCard(waigrCode);
    refreshDataEventEmitter.emit('refreshData');
  };

  return (
    <>
      <div className="race" onClick={() => setIsNavOpen((prev) => !prev)}>
        <h2>
          {race?.trackName} <LuMenu className="inline ml-1" />
        </h2>
        <p>Race: {race?.number ?? 'N/A'}</p>
        {race?.mtp && <p className="mtp">{race?.mtp?.minutesToPost} MTP</p>}
      </div>

      {race?.streaming.web && (
        <div className="race">
          <button
            type="button"
            className="text-white hover:bg-red-800 font-medium rounded-full text-sm px-3 py-1 text-center mb-2 bg-red-600"
            onClick={handleLiveButtonClick}>
            <span className="animate-pulse">
              <RxVideo className="inline" /> LIVE
            </span>
          </button>
        </div>
      )}

      <div
        className={`fixed top-0 right-0 w-full h-full bg-black z-50 opacity-80 font-semibold transform transition-transform duration-500 ease-in-out ${
          isNavOpen ? 'translate-x-0' : 'translate-x-full'
        }`}>
        <div className="absolute top-0 right-0 px-8 py-8" onClick={() => setIsNavOpen(false)}>
          <svg
            className="h-8 w-8 text-gray-600"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
        <ul className="flex flex-col items-center justify-between min-h-[250px] mt-24">
          <li key="all" onClick={() => selectCardHandler('ALL')} className="my-6 cursor-pointer">
            <span className={`font-semibold ${selectedCard === 'ALL' || !selectedCard ? 'text-[#6cec48]' : 'text-white'}`}>
              Follow All Tracks {(selectedCard === 'ALL' || !selectedCard) && <IoCheckmarkCircleSharp className="inline ml-3" />}
            </span>
          </li>
          {todayCards.map((card) => (
            <li key={card.cardId} onClick={() => selectCardHandler(card.waigrCode)} className="my-6 cursor-pointer">
              <span className={`font-semibold ${selectedCard === card.waigrCode ? 'text-[#6cec48]' : 'text-white'}`}>{card.cardName}</span> -{' '}
              <span className={`font-normal ${card.mtp.inFuture ? 'text-yellow-300' : 'text-red-400'} `}>
                {!card.mtp.inFuture ? '-' : ''}
                {card.mtp.minutesToPost} MTP
              </span>
              {selectedCard === card.waigrCode && <IoCheckmarkCircleSharp className="text-[#6cec48] inline ml-3" />}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default RaceInfo;
