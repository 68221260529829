import useBets from '../hooks/useBets';
import { PlacedBetsProps } from '../interfaces';
import LoadingSpinner from './LoadingSpinner';
import PlacedBetItem from './PlacedBetItem';

const PlacedBets = ({ bets }: PlacedBetsProps) => {
  const { loading } = useBets();

  if (!bets) {
    return null;
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {bets.length > 0 ? (
            <>
              <h1 className="text-white font-semibold mt-20">Waigr Bets with Nyra on this Race</h1>
              <ul className="pb-64">
                {bets.map((bet: any) => (
                  <li key={bet.id}>
                    <PlacedBetItem bet={bet} />
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <p className="text-red-500 font-semibold mt-10">You didn't place bets on this race.</p>
          )}
        </>
      )}
    </>
  );
};

export default PlacedBets;
