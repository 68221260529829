import React, { useState } from 'react';
import './App.css';
import LoginWidget from './components/LoginWidget';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LiveBetting from './pages/LiveBetting';
import MyBets from './pages/MyBets';
import MyAccount from './pages/MyAccount';
import FairOdds from './components/FairOdds';
import Header from './components/Header';
import RaceSchedule from './pages/RaceSchedule';
import SingleRace from './pages/SingleRace';
import useBets from './hooks/useBets';
import { ToastContainer } from 'react-toastify';
import Video from './pages/Video';

const App: React.FC = () => {
  const isSessionTokenInStorage = () => {
    const token = localStorage.getItem('sessionToken');
    if (token && token?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [loggedIn, setLoggedIn] = useState(isSessionTokenInStorage);
  const { race } = useBets();

  const handleLogin = () => {
    localStorage.removeItem('selectedCard');
    setLoggedIn(true);
  };

  return (
    <>
      {loggedIn ? (
        <Router>
          <div className="app-container">
            <Header />
            <ToastContainer
              position="top-right"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            <Routes>
              <Route path="/" element={<LiveBetting />} />
              <Route path="/my-bets" element={<MyBets />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/fair-odds" element={<FairOdds race={race} />} />
              <Route path="/race-schedule" element={<RaceSchedule />} />
              <Route path="/race/:raceId" element={<SingleRace />} />
              <Route path="/video" element={<Video />} />
            </Routes>
          </div>
        </Router>
      ) : (
        <LoginWidget onLogin={handleLogin} />
      )}
    </>
  );
};

export default App;
