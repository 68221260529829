import useBets from '../hooks/useBets';
import LoadingSpinner from '../components/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import FairOdds from '../components/FairOdds';
import PlacedBets from '../components/PlacedBets';

const SingleRace = () => {
  const { loading, selectedRace, getSingleRace } = useBets();
  const { raceId } = useParams();

  const raceIdNumber = raceId ? parseInt(raceId, 10) : null;

  useEffect(() => {
    if (raceIdNumber) {
      getSingleRace(raceIdNumber); // Always fetch race on mount
    }
  }, [getSingleRace, raceIdNumber]);

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {selectedRace ? (
            <>
              <FairOdds race={selectedRace} />
              <PlacedBets bets={selectedRace.userBets} />
            </>
          ) : (
            <p className="text-red-500 font-semibold">Race Details not available</p>
          )}
        </>
      )}
    </div>
  );
};

export default SingleRace;
