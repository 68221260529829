import React from 'react';
import { BalanceInterface } from '../interfaces';
import useBets from '../hooks/useBets';
import LoadingSpinner from './LoadingSpinner';

interface BalanceInfoProps {
  balance?: BalanceInterface;
}

export const currencyFormat = function (num: any) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const BalanceInfo: React.FC<BalanceInfoProps> = ({ balance }) => {
  const { loading } = useBets();

  if (!balance) return null;

  if (loading) return <LoadingSpinner />;

  return (
    <div className="text-[#6cec48]">
      $<span>{currencyFormat(balance?.availableBalance?.amount) ?? 'N/A'}</span>
    </div>
  );
};

export default BalanceInfo;
