import useBets from '../hooks/useBets';
import LoadingSpinner from '../components/LoadingSpinner';
import { RaceSchedule as RaceScheduleInterface } from '../interfaces';
import { FaEyeSlash } from 'react-icons/fa';
import { BiSolidBinoculars } from 'react-icons/bi';
import { LiaHorseHeadSolid } from 'react-icons/lia';
import { MdOutlineTimer } from 'react-icons/md';
import { useEffect } from 'react';
import { MdQueryStats } from 'react-icons/md';
import { Link } from 'react-router-dom';

const RaceSchedule = () => {
  const { raceSchedule, setRaceSchedule, loading, skipRace, followRace, getRaceSchedule } = useBets();

  useEffect(() => {
    // Set up an interval to refresh the race schedule every 10 seconds
    const intervalId = setInterval(() => {
      getRaceSchedule();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [getRaceSchedule]);

  const handleSkipRace = (raceId: number) => {
    const newRaceSchedule = raceSchedule.map((raceScheduleItem: RaceScheduleInterface) => {
      if (raceScheduleItem.raceId === raceId) {
        raceScheduleItem.isUserSkipped = true;
      }
      return raceScheduleItem;
    });

    skipRace(raceId);
    setRaceSchedule(newRaceSchedule);
  };

  const handleFollowRace = (raceId: number) => {
    const newRaceSchedule = raceSchedule.map((raceScheduleItem: RaceScheduleInterface) => {
      if (raceScheduleItem.raceId === raceId) {
        raceScheduleItem.isUserSkipped = false;
      }
      return raceScheduleItem;
    });

    followRace(raceId);
    setRaceSchedule(newRaceSchedule);
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="fair-odds mt-5">
            <h2 className="font-semibold">Today's Schedule</h2>
            <div className="flex items-center justify-center mt-9 pb-9">
              {raceSchedule && raceSchedule.length > 0 ? (
                <table className="table-auto">
                  <thead>
                    <tr>
                      <th className="px-4 py-2">
                        <LiaHorseHeadSolid className="inline" /> Race
                      </th>
                      <th className="px-4 py-2">
                        <MdOutlineTimer className="inline" />
                      </th>
                      <th className="px-4 py-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {raceSchedule.map((race: any) => (
                      <tr key={race.raceId} className={`px-4 py-2`}>
                        <td
                          className={`truncate px-4 py-2 ${race.isNext ? 'bg-green-700 opacity-90' : ''} ${
                            race.isUserSkipped ? 'line-through' : ''
                          }`}>
                          {race.title.replace('Race ', 'R')}
                        </td>
                        <td className={`px-4 py-2 ${race.isNext ? 'bg-green-700 opacity-90' : ''}`}>
                          {race.mtp.inFuture ? race.mtp.minutesToPost + "'" : 'N/A'}
                        </td>
                        <td className={`flex items-center px-4 py-2 ${race.isNext ? 'bg-green-700 opacity-90' : ''}`}>
                          {!race.isUserSkipped && race.mtp.inFuture && (
                            <button
                              className="bg-transparent hover:bg-yellow-400 text-yellow-500 hover:text-white py-2 px-4 border border-yellow-500 hover:border-transparent rounded-full"
                              onClick={() => handleSkipRace(race.raceId)}>
                              <FaEyeSlash />
                            </button>
                          )}

                          {race.isUserSkipped && race.mtp.inFuture && (
                            <button
                              className="bg-transparent hover:bg-green-400 text-green-500 hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded-full"
                              onClick={() => handleFollowRace(race.raceId)}>
                              <BiSolidBinoculars />
                            </button>
                          )}

                          <Link
                            className="ml-2 bg-transparent hover:bg-stone-400 text-white hover:text-white py-2 px-4 border border-white hover:border-transparent rounded-full"
                            to={'/race/' + race.raceId}>
                            <MdQueryStats />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-red-500 font-semibold">Race Schedule not available yet</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RaceSchedule;
