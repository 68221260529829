import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import BetItem from './BetItem';
import { BetListProps, BetSuggestion } from '../interfaces';
import { HoldToConfirmButton } from './HoldToConfirmButton';
import useBets from '../hooks/useBets';
import LoadingSpinner from './LoadingSpinner';
import { currencyFormat } from './BalanceInfo';

const BetList: React.FC<BetListProps> = ({ bets, race }) => {
  const { placeBet, loading } = useBets();
  const [emblaRef] = useEmblaCarousel();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!race) {
    return <p className="text-white font-bold mt-20 pt-20 pb-80">Race schedule is empty, please try again later </p>;
  }

  if (race && bets.length === 0) {
    return (
      <p className="text-white font-bold mt-20 pt-20 pb-80">
        More waigrs will be generated for you
        <br />
        <br /> in a few minutes{' '}
      </p>
    );
  }

  return (
    <div className="embla !mt-40" ref={emblaRef}>
      <div className="embla__container">
        {bets.map((betSuggestion: BetSuggestion) => (
          <div
            key={betSuggestion.groupId}
            className="bet-item embla__slide p-4 m-12 relative"
            style={{ minHeight: betSuggestion.items.length > 1 ? '250px' : '210px' }}>
            {betSuggestion.items.length > 0 && <h2 className="mb-2 mt-2 bet-item-title">{betSuggestion.items[0].title}</h2>}
            {betSuggestion.items.map((item: any, index: number) => (
              <BetItem key={index} item={item} />
            ))}

            {(() => {
              const IDs: Array<number> = [];
              betSuggestion.items.forEach((item: any) => {
                IDs.push(item.id);
              });
              return loading ? (
                <LoadingSpinner />
              ) : (
                <div className="absolute bottom-4 left-0 w-full">
                  <HoldToConfirmButton onSubmit={() => placeBet(IDs, betSuggestion.groupId)}>
                    Total: <span className="text-light-green">{currencyFormat(betSuggestion.total)}</span>
                  </HoldToConfirmButton>
                </div>
              );
            })()}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BetList;
