import React from 'react';

export const HoldToConfirmButton = ({ onSubmit, holdDelay = 1000, children }: any) => {
  const [percentage, setPercentage] = React.useState(0);
  const startTime = React.useRef(null);
  const holdIntervalRef = React.useRef(null);

  React.useEffect(() => {
    return () => {
      holdIntervalRef.current && clearInterval(holdIntervalRef.current);
    };
  }, []);

  const startCounter = () => {
    if (holdIntervalRef.current) {
      return;
    }
    //@ts-ignore
    startTime.current = Date.now();

    //@ts-ignore
    holdIntervalRef.current = setInterval(() => {
      if (startTime.current) {
        setPercentage(Math.floor(((Date.now() - startTime.current) / holdDelay) * 100));
        if (Date.now() - startTime.current > holdDelay) {
          stopCounter();
          onSubmit();
        }
      }
    }, 10);
  };

  const stopCounter = () => {
    startTime.current = null;
    setPercentage(0);
    if (holdIntervalRef.current) {
      clearInterval(holdIntervalRef.current);
      holdIntervalRef.current = null;
    }
  };

  return (
    <button
      onMouseDown={startCounter}
      onMouseUp={stopCounter}
      onMouseLeave={stopCounter}
      onTouchStart={startCounter}
      onTouchCancel={stopCounter}
      onTouchEnd={stopCounter}
      className="animate-pulse border-green-800 border-solid border-2 text-white font-bold squared mt-5 no-user-select"
      style={{
        background: 'linear-gradient(green, green) no-repeat top left',
        backgroundSize: `${percentage}%`,
      }}>
      {children}
    </button>
  );
};
